import { Logger } from "@/logger";
import { Viewer } from "@inweb/viewer-visualize/";
import SelectDragger from "./draggers/selectDragger";
import EllipseDragger from "./draggers/EntityDraggers/EllipseDragger";
import RectangleDragger from "./draggers/EntityDraggers/RectangleDragger";
import FreeLineDragger from "./draggers/EntityDraggers/FreeLineDragger";
import StraightLineDragger from "./draggers/EntityDraggers/StraightLineDragger";
import TextDragger from "./draggers/EntityDraggers/TextDragger";
import BlockDragger from "./draggers/EntityDraggers/BlockDragger";
import PhotoMarkerDragger from "./draggers/EntityDraggers/PhotoMarkerDragger";
import PolylineDragger from "./draggers/EntityDraggers/PolylineDragger";
import MeasureDragger from "./draggers/measureDragger";
import LeaderDragger from "./draggers/EntityDraggers/LeaderDragger";
import DrZoomToWindow from "./draggers/ZoomPanDraggers/ZoomExtentWindowDragger";
import Toolbox from "./builders/ODAToolbox";
import type { OdaBinaryFormat } from "@/repositories/domain.types";
import { odaRepository } from "@/repositories/oda.repository";
import { drawingRepository } from "@/repositories/drawing.repository";
import {
  DraggerName,
  type DraggerConfig,
} from "@/open-cloud/draggers/draggers.type";
import WheelSpaceKeyPanDragger from "@/open-cloud/draggers/ZoomPanDraggers/wheelSpaceKeyPanDragger";
import { OriginDragger } from "./draggers/originDragger";
import { Transformer } from "@/open-cloud/draggers/Transform/transformer";
import IdentityDragger from "@/open-cloud/draggers/identityDragger";
import { EntityBuilder } from "./builders/EntityBuilder";
import { type Layer, LayerBuilder } from "./builders/LayerBuilder";
import { TextStyleBuilder } from "./builders/TextStyleBuilder";
import { LineweightBuilder } from "./builders/LineweightBuilder";
import { ModelBuilder } from "./builders/ModelBuilder";
import { DeviceBuilder } from "./builders/DeviceBuilder";
import { TextBuilder } from "./builders/TextBuilder";
import { BlockBuilder, type BlockConfig } from "./builders/BlockBuilder";
import { SelectionSetBuilder } from "./builders/SelectionSetBuilder";
import { ColorDefBuilder } from "./builders/ColorDefBuilder";
import Ruler from "./ruler";
import type { NoteConfig } from "@/stores/UserState";
import { DEFAULT_NOTE_CONFIG } from "@/defaultLegend";
import { PhotoMarker } from "@/open-cloud/photoMarker";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";
import { MarkupType } from "@inweb/viewer-visualize/src/Viewer/Markup/IMarkup";
import DRGestureManager from "./draggers/ZoomPanDraggers/DRGestureManager";
import CommandFactory from "./commands/CommandFactory";
import { ViewBuilder } from "./builders/ViewBuilder";
import { HistoryEvents } from "./commands/History";
import { GeometryBuilder } from "./builders/GeometryBuilder";
import HatchBuilder from "./draggers/EntityDraggers/HatchBuilder";
import ShellBuilder from "./builders/ShellBuilder";

// Local VisualizeJS is convenient to develop offline but can lead to issue with cache

export const VISUALIZE_URL =
  // use CDN :
  //"https://opencloud.azureedge.net/libs/visualizejs/25.6/Visualize.js";
  // use local file
  "/VisualizeJS/25_6/Visualize.js";

export const TEMP_LAYER: Layer = {
  name: "__DR_TEMP",
  visibility: false,
};

export enum ViewerEvents {
  OpenDrawing = "open",
  DrawingEdited = "edit",
  RenderLatency = "RenderLatency",
}

export class DRViewer extends Viewer {
  drawingId: number | undefined = undefined;

  internalCanvas: HTMLCanvasElement | null | undefined = undefined;
  toolbox: Toolbox = new Toolbox(this);
  entityBuilder: EntityBuilder = new EntityBuilder(this);
  lineweightBuilder: LineweightBuilder = new LineweightBuilder(this);
  textStyleBuilder: TextStyleBuilder = new TextStyleBuilder(this);
  layerBuilder: LayerBuilder = new LayerBuilder(this);
  modelBuilder: ModelBuilder = new ModelBuilder(this);
  deviceBuilder: DeviceBuilder = new DeviceBuilder(this);
  textBuilder: TextBuilder = new TextBuilder(this);
  blockBuilder: BlockBuilder = new BlockBuilder(this);
  colorDefBuilder: ColorDefBuilder = new ColorDefBuilder(this);
  selectionSetBuilder: SelectionSetBuilder = new SelectionSetBuilder(this);
  viewBuilder: ViewBuilder = new ViewBuilder(this);
  geometryBuilder: GeometryBuilder = new GeometryBuilder(this);
  hatchPatternBuilder: HatchBuilder = new HatchBuilder(this);
  shellBuilder: ShellBuilder = new ShellBuilder(this);

  measureDragger: MeasureDragger = new MeasureDragger(this);

  activeNoteConfig: NoteConfig = DEFAULT_NOTE_CONFIG;
  activeLayerConfigs: Layer[] = [];
  activeBlock: VisualizeJS.OdTvBlockId | null = null;
  activeText = "";
  activePhotoMarkerNumber = "";
  activePhotoMarker: BlockConfig | null = null;
  activePhotoMarkerId: VisualizeJS.OdTvBlockId | null = null;

  selectionSet: VisualizeJS.OdTvSelectionSet | null = null;

  defaultDragger?: DraggerConfig;
  previousDragger?: DraggerConfig;
  resizer?: Transformer;
  photoMarker?: PhotoMarker;
  ruler: Ruler = new Ruler(this);
  beenEdited = false;

  onlyStylusOn: boolean;
  closeContour = true;
  smoothen = true;
  label = true;
  private gestureManager: DRGestureManager | null = null;
  private wheelSpaceKeyPanDragger: WheelSpaceKeyPanDragger | null = null;

  tempLayer?: VisualizeJS.OdTvLayerId;
  commandFactory: CommandFactory = new CommandFactory(this);

  constructor(
    params: {
      onlyStylusOn: boolean;
    } = {
      onlyStylusOn: true,
    }
  ) {
    super(odaRepository.client, {
      visualizeJsUrl: VISUALIZE_URL,
      markupType: MarkupType.Visualize,
    });
    this.onlyStylusOn = params.onlyStylusOn;

    this.createDraggers([
      { name: DraggerName.DrSelect },
      { name: DraggerName.DrEllipse },
      { name: DraggerName.DrRectangle },
      { name: DraggerName.DrFreeLine },
      { name: DraggerName.DrStraightLine },
      { name: DraggerName.DrPolyline },
      { name: DraggerName.DrText },
      { name: DraggerName.PhotoMarker },
      { name: DraggerName.Identity },
      { name: DraggerName.DrOrigin },
      { name: DraggerName.DrLeader },
      { name: DraggerName.DrZoomToWindow },
    ]);
  }

  createDraggers(draggerConfigs: DraggerConfig[]) {
    draggerConfigs.forEach((config) => {
      switch (config.name) {
        case "DrSelect":
          return this.draggerFactory.set(config.name, SelectDragger);
        case "Block":
          return this.draggerFactory.set(config.name, BlockDragger);
        case "DrText":
          return this.draggerFactory.set(config.name, TextDragger);
        case "DrOrigin":
          return this.draggerFactory.set(config.name, OriginDragger);
        case "Identity":
          return this.draggerFactory.set(config.name, IdentityDragger);
        case "DrEllipse":
          return this.draggerFactory.set(config.name, EllipseDragger);
        case "DrRectangle":
          return this.draggerFactory.set(config.name, RectangleDragger);
        case "DrFreeLine":
          return this.draggerFactory.set(config.name, FreeLineDragger);
        case "DrStraightLine":
          return this.draggerFactory.set(config.name, StraightLineDragger);
        case "PhotoMarker":
          return this.draggerFactory.set(config.name, PhotoMarkerDragger);
        case "DrPolyline":
          return this.draggerFactory.set(config.name, PolylineDragger);
        case "DrLeader":
          return this.draggerFactory.set(config.name, LeaderDragger);
        case "DrZoomToWindow":
          return this.draggerFactory.set(config.name, DrZoomToWindow);
      }
    });
  }

  setDefaultDragger(dragger: DraggerConfig) {
    this.defaultDragger = dragger;
  }

  static disableTouchMove(event: Event) {
    event.preventDefault();
  }

  async initialize(
    canvas: HTMLCanvasElement,
    onProgress?: (event: ProgressEvent) => void
  ): Promise<this> {
    await super.initialize(canvas, onProgress);

    this.options.data = {
      reverseZoomWheel: true,
      enableZoomWheel: true,
      enableGestures: false,
      showWCS: false,
      sceneGraph: true,
      edgeModel: false,
    };

    this.gestureManager = new DRGestureManager(this, this.onlyStylusOn);
    this.gestureManager.initialize();

    this.wheelSpaceKeyPanDragger = new WheelSpaceKeyPanDragger(this);
    this.wheelSpaceKeyPanDragger.initialize();

    this.photoMarker = new PhotoMarker(this);

    this.resizer = new Transformer(canvas, this, this.toolbox);

    OdaGeometryUtils.initialize(this);

    // @ts-expect-error: action is a custom defined event (for undo/redo stack)
    this.addEventListener(ViewerEvents.DrawingEdited, () => {
      this.beenEdited = true;
    });

    // If entities that are in selectionset are affected by undo redo, it can trigger problems like empty grids, etc...
    // Quick fix is to empty selection set at undo redo

    this.addEventListener(HistoryEvents.RedoEnded, () =>
      this.selectionSetBuilder.resetSelectionSetWithoutEmitEvent()
    );
    this.addEventListener(HistoryEvents.UndoEnded, () =>
      this.selectionSetBuilder.resetSelectionSetWithoutEmitEvent()
    );

    return this;
  }

  dispose() {
    this.drawingId = undefined;
    this.gestureManager?.dispose();
    this.wheelSpaceKeyPanDragger?.dispose();
    this.photoMarker?.dispose();
    this.resizer?.dispose();
    this.internalCanvas?.removeEventListener(
      "touchmove",
      DRViewer.disableTouchMove
    );
    this.internalCanvas = null;
    this.activeNoteConfig = { name: "", props: {} };
    this.activeText = "";
    this.activeLayerConfigs = [];
    delete this.defaultDragger;
    delete this.previousDragger;
    delete this.photoMarker;
    this.beenEdited = false;
    this.clearTempLayer();
    super.dispose();
    return this;
  }

  activateDragger(config: DraggerConfig): void {
    super.setActiveDragger(config.name);
    // in super.setActiveDragger, activeDragger.initialize is called
    // setEnableAutoSelect is called as well, we don't want it to be activated.
    this.visViewer().setEnableAutoSelect(false);
  }

  async initVsfViewer() {
    if (this.defaultDragger) {
      Logger.warn("DRViewer.initVsfViewer() : missing default dragger");
      this.activateDragger(this.defaultDragger);
    }

    // syncoverlay is used to show the markup model in a sibling view
    this.syncOverlay();

    // Remove all the transformation grid (resize, rotate)

    this.visViewer().getMarkupModel().clearEntities();
    this.photoMarker?.scanForMarkers();
    try {
      await this.textStyleBuilder.embedFontFiles();
    } catch {
      Logger.error(`DRViewer.initVsfViewer : can't embed font files`);
    }

    this.viewBuilder.initLineWeightMode();
    this.viewBuilder.initVisualStyle();

    this.createTempLayer();

    this.emitEvent(
      new CustomEvent(ViewerEvents.OpenDrawing, {
        bubbles: false,
        detail: {
          viewer: this,
        },
      })
    );
  }

  async openEmptyCanvas() {
    this.cancel();
    Logger.warn(
      `DRViewer.openEmptyCanvas() : Opening empty canvas, ${this.drawingId}`
    );
  }
  async openVsf(drawingId: number, vsf: ArrayBuffer, format: OdaBinaryFormat) {
    this.cancel();
    this.beenEdited = false;
    this.drawingId = drawingId;
    if (vsf && vsf.byteLength) {
      if (format === "VSF") {
        this.openVsfFile(vsf);
      } else if (format === "VSFX") {
        this.openVsfxFile(vsf);
      } else {
        Logger.error(`Invalid format of binary geometry: ${format}`);
      }
    } else {
      Logger.error(`Cannot open empty vsf`);
    }
  }

  async save(): Promise<void> {
    this.visViewer().saveVsfx((data: Int8Array) => {
      if (!this.beenEdited) {
        return;
      }
      if (!this.drawingId) {
        Logger.warn(`DRViewer.save(): No file is open, cannot save it!`);
        throw new Error("No file is open, cannot save it!");
      }
      drawingRepository.saveDraft(this.drawingId, new Int8Array(data).buffer);
    });
  }

  setActiveText(text: string) {
    const activeDragger = this.activeDragger();
    if (activeDragger?.name == DraggerName.DrText) {
      this.activeText = text;
    }
  }

  clearTempLayer() {
    this.tempLayer?.delete();
    this.tempLayer = undefined;
  }

  createTempLayer() {
    Logger.info(
      `DRViewer.createTempLayer : creating temp layer ${TEMP_LAYER.name}`
    );
    this.layerBuilder.putLayer(TEMP_LAYER);
    this.tempLayer = this.visViewer().findLayer(TEMP_LAYER.name);
  }

  putLayer() {
    if (this.activeNoteConfig.props.layername) {
      const layerProps = this.activeLayerConfigs.find(
        (layer) => layer.name === this.activeNoteConfig.props.layername
      );
      if (layerProps) this.layerBuilder.putLayer(layerProps);
    }
  }
}
