import type { HatchPattern } from "@/open-cloud/draggers/EntityDraggers/HatchBuilder";

const DR_HATCHES: { id: number; name: string; hatches: HatchPattern }[] = [
  {
    id: 1,
    name: "ANSI31",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [1.8, 0],
          },
        ],
      },
    },
  },
  {
    id: 2,
    name: "ANSI32",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [1.8, 0],
          },
          {
            angle: 0.7853,
            offset: [1.8, 0],
            basePoint: [0.9, 0],
          },
        ],
      },
    },
  },
  {
    id: 3,
    name: "GRASS",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: Math.PI / 4,
            offset: [1.2, 0],
            dashes: [
              (1.2 / Math.cos(Math.PI / 4)) * 0.3,
              -(1.2 / Math.cos(Math.PI / 4)) * 0.7,
            ],
          },
          {
            angle: (3 * Math.PI) / 4,
            offset: [1.2, 0],
            dashes: [
              (1.2 / Math.cos(Math.PI / 4)) * 0.3,
              -(1.2 / Math.cos(Math.PI / 4)) * 0.7,
            ],
          },
          {
            angle: Math.PI / 2,
            offset: [1.2, 0],
            dashes: [0.6, -0.6],
          },
        ],
      },
    },
  },
  {
    id: 4,
    name: "ANSI33",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [1.8, 0],
          },
          {
            angle: 0.7853,
            offset: [1.8, 0],
            basePoint: [0.9, 0],
            dashes: [1.8, -1.8],
          },
        ],
      },
    },
  },
  {
    id: 5,
    name: "HUM",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [1.8, 0],
            dashes: [
              (1.8 / Math.cos(Math.PI / 4)) * 0.3,
              -(1.8 / Math.cos(Math.PI / 4)) * 0.7,
            ],
          },
          {
            angle: 0.7853,
            basePoint: [0.18, -0.18],
            offset: [1.8, 0],
            dashes: [
              (1.8 / Math.cos(Math.PI / 4)) * 0.3,
              -(1.8 / Math.cos(Math.PI / 4)) * 0.7,
            ],
          },
          {
            angle: -0.7853,
            basePoint: [0.18, 0.54],
            offset: [1.8, 0],
            dashes: [
              (1.8 / Math.cos(Math.PI / 4)) * 0.3,
              -(1.8 / Math.cos(Math.PI / 4)) * 0.7,
            ],
          },
          {
            angle: -0.7853,
            basePoint: [0.0, 0.36],
            offset: [1.8, 0],
            dashes: [
              (1.8 / Math.cos(Math.PI / 4)) * 0.3,
              -(1.8 / Math.cos(Math.PI / 4)) * 0.7,
            ],
          },
        ],
      },
    },
  },
];

export default class HatchLibrary {
  static getId(id: number) {
    for (const hatch of DR_HATCHES) {
      if (hatch.id === id) return hatch;
    }
  }
}
