import type { DRViewer } from "@/open-cloud/DRViewer";
import type { Point3d } from "@inweb/viewer-visualize/lib/Viewer/Draggers/Common/Geometry";
import type { pointArray } from "../types/oda.types";

export default class OdaGeometryUtils {
  static viewer?: DRViewer;

  public static initialize(viewer: DRViewer) {
    OdaGeometryUtils.viewer = viewer;
  }

  private static get visLib(): typeof VisualizeJS {
    return this.viewer?.visLib();
  }

  private static get visViewer(): VisualizeJS.Viewer {
    return this.viewer?.visViewer();
  }

  static radToDeg(rad: number) {
    return (rad * 180) / Math.PI;
  }

  static logMatrix(m: VisualizeJS.Matrix3d) {
    let out = "";
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        out += m.get(i, j) + " ";
      }
      out += " \n";
    }
    return out;
  }

  static createNewMatrix(clone?: VisualizeJS.Matrix3d): VisualizeJS.Matrix3d {
    const matrix = new OdaGeometryUtils.visLib.Matrix3d().setToIdentity();
    if (clone) {
      matrix.preMultBy(clone);
    }
    return matrix;
  }

  static getRotationMatrix(
    center: pointArray,
    radAngle: number
  ): VisualizeJS.Matrix3d {
    const rotationMatrix: VisualizeJS.Matrix3d =
      new OdaGeometryUtils.visLib.Matrix3d();
    rotationMatrix.setToRotation(radAngle, [0, 0, 1], center);
    return rotationMatrix;
  }

  static getTranslationMatrix(
    translationVector: VisualizeJS.Vector3
  ): VisualizeJS.Matrix3d {
    const translationMatrix: VisualizeJS.Matrix3d =
      new OdaGeometryUtils.visLib.Matrix3d();
    translationMatrix.setTranslation(translationVector);
    return translationMatrix;
  }
  static getScaleMatrix(
    scaleVector: VisualizeJS.Vector3
  ): VisualizeJS.Matrix3d {
    const scaleMatrix: VisualizeJS.Matrix3d =
      new OdaGeometryUtils.visLib.Matrix3d();
    scaleMatrix.set(0, 0, scaleVector[0]);
    scaleMatrix.set(1, 1, scaleVector[1]);
    return scaleMatrix;
  }
  static getScalesFromMatrix(
    matrix: VisualizeJS.Matrix3d
  ): VisualizeJS.Vector3 {
    const vector: VisualizeJS.Vector3 = [1, 1, 1];
    vector[0] = matrix.get(0, 0);

    return vector;
  }

  static createPoint3dFromArray(array: pointArray): Point3d {
    return OdaGeometryUtils.visLib.Point3d.createFromArray(array);
  }

  // A 3d Matrix can be mapped uniquely to 4 vectors.
  // The results vector of the matrix product and WCS coord base vector x,y,z
  // origin is needed to take into accound translation matrix
  static getMatrixCoordVectors(matrix: VisualizeJS.Matrix3d) {
    return {
      origin: matrix.getCsOrigin(),
      x: matrix.getCsXAxis(),
      y: matrix.getCsYAxis(),
      z: matrix.getCsZAxis(),
    };
  }
  // creates a matrix from vectors, invert function of "getMatrixCoordVectors"
  static getMatrixFromVectors(
    origin: VisualizeJS.Point3,
    x: VisualizeJS.Point3,
    y: VisualizeJS.Point3,
    z: VisualizeJS.Point3
  ): VisualizeJS.Matrix3d {
    const matrix = new OdaGeometryUtils.visLib.Matrix3d();
    matrix.setCoordSystem(origin, x, y, z);
    return matrix;
  }

  /**
   * Calculates a new matrix similar to old but with the scale part changed to new scale
   * @param oldMatrix the current matrix where scale part is to be changed
   * @param newScale the target scale
   * @param initialScale the scale the block was initially defined with
   * @returns matrix BlockTransform to apply
   */
  static reScaleMatrix(
    oldMatrix: VisualizeJS.Matrix3d,
    newScale: number,
    initialScale = 1
  ): VisualizeJS.Matrix3d {
    const oldScale = oldMatrix.norm(); // get current scale
    const center = oldMatrix.getCsOrigin();
    const scaleMatrix = OdaGeometryUtils.createNewMatrix().setToScaling(
      newScale / (initialScale * oldScale),
      center
    );
    const incremented = scaleMatrix.postMultBy(oldMatrix);
    scaleMatrix.delete();
    oldMatrix.delete();
    return incremented;
  }
  /**
   * Calculates the perimeter points of an ellipse
   * @param center of the ellipse
   * @param R0 radius for theta = 0
   * @param Rn radius for theta = PI/2
   * @param count number of points on the ellipse
   * @returns an array of point ([number, number, number][]) from theta 0 to 2Pi
   */
  static getEllipsePerimeterPoints(
    center: [number, number, number],
    R0: number,
    Rn: number,
    count: number
  ): [number, number, number][] {
    // number of points has to be greater than 1
    if (count <= 1) return [];

    // set value of thetas (radians) for the ellipse
    const thetas = [];
    for (let i = 0; i < count; i++) {
      thetas[i] = (i * 2 * Math.PI) / count;
    }

    // calculate polar radius of points depending on thetas
    const R: number[] = [];
    for (let i = 0; i < count; i++) {
      R[i] = Math.sqrt(
        (R0 ** 2 * Rn ** 2) /
          (Math.pow(Rn * Math.cos(thetas[i]), 2) +
            Math.pow(R0 * Math.sin(thetas[i]), 2))
      );
    }
    // go from polar to euclidian coords and translate to center
    const XYZ: [number, number, number][] = [];

    for (let i = 0; i < count; i++) {
      XYZ[i] = [
        center[0] + R[i] * Math.cos(thetas[i]),
        center[1] + R[i] * Math.sin(thetas[i]),
        center[2],
      ];
    }
    return XYZ;
  }

  /**
   * Corner 1 and corner 2 can be any opposite corners
   * Returns ordered corners
   * @param corner1
   * @param corner2
   * @returns
   */
  static computeRectangleCorners(
    corner1: VisualizeJS.Point3,
    corner2: VisualizeJS.Point3
  ) {
    let bottomLeft, bottomRight, topRight, topLeft: VisualizeJS.Point3;
    if (corner1[0] < corner2[0] && corner1[1] < corner2[1]) {
      bottomLeft = corner1;
      bottomRight = [corner2[0], corner1[1], corner1[2]];
      topRight = corner2;
      topLeft = [corner1[0], corner2[1], corner1[2]];
    } else if (corner1[0] < corner2[0] && corner1[1] >= corner2[1]) {
      bottomLeft = [corner1[0], corner2[1], corner1[2]];
      bottomRight = corner2;
      topRight = [corner2[0], corner1[1], corner1[2]];
      topLeft = corner1;
    } else if (corner1[0] >= corner2[0] && corner1[1] < corner2[1]) {
      bottomLeft = [corner2[0], corner1[1], corner1[2]];
      bottomRight = corner1;
      topRight = [corner1[0], corner2[1], corner1[2]];
      topLeft = corner2;
    } else {
      bottomLeft = corner2;
      bottomRight = [corner1[0], corner2[1], corner1[2]];
      topRight = corner1;
      topLeft = [corner2[0], corner1[1], corner1[2]];
    }
    return {
      bottomLeft,
      bottomRight,
      topRight,
      topLeft,
    };
  }
}
