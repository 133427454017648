import type { DRViewer } from "@/open-cloud/DRViewer";
import Toolbox from "./ODAToolbox";
import { Logger } from "@/logger";
import OdaGeometryUtils from "./odaGeometry.utils";
import type { GeometryDataTyped } from "./BlockBuilder";

export interface PolylineData {
  points: VisualizeJS.Point3[];
}

export interface CircleData {
  center: VisualizeJS.Point3;
  radius: number;
  normal?: VisualizeJS.Point3;
}

export interface EllipseData {
  center: VisualizeJS.Point3;
  minorPoint: VisualizeJS.Point3;
  majorPoint: VisualizeJS.Point3; // position of the
}

export class GeometryBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  static iterateGeometries(
    itr: VisualizeJS.OdTvGeometryDataIterator,
    cb: (geomId: VisualizeJS.OdTvGeometryDataId) => void
  ) {
    Toolbox.iterateBase(itr, cb, (itr) => itr.getGeometryData());
  }

  static logProperties(geomId: VisualizeJS.OdTvGeometryDataId) {
    //const geom = geomId.openObject();
    Logger.info(
      `GeomtryBuilder : log geom prop type : ${geomId.getTypeEnum().value}`
    );
  }
  // add a polyline to the entity
  static addPolyline(
    entId: VisualizeJS.OdTvEntityId,
    data: PolylineData
  ): VisualizeJS.OdTvGeometryDataId {
    const ent = entId.openObject();
    const flatten: number[] = [];
    data.points.forEach((point) => flatten.push(...point));
    const geomId = ent.appendPolyline(flatten);
    ent.delete();
    return geomId;
  }

  static addCircle(
    entId: VisualizeJS.OdTvEntityId,
    data: CircleData
  ): VisualizeJS.OdTvGeometryDataId {
    const ent = entId.openObject();
    const normal: VisualizeJS.Point3 = data.normal ? data.normal : [0, 0, 1];
    const geomId = ent.appendCircleWithNormal(data.center, data.radius, normal);
    ent.delete();
    return geomId;
  }

  static addEllipse(
    entId: VisualizeJS.OdTvEntityId,
    data: EllipseData
  ): VisualizeJS.OdTvGeometryDataId {
    const ent = entId.openObject();
    const geomId = ent.appendEllipse(
      data.center,
      data.majorPoint,
      data.minorPoint
    );
    ent.delete();
    return geomId;
  }

  static setModelingMatrix(
    geomIds: VisualizeJS.OdTvGeometryDataId[],
    matrix: VisualizeJS.Matrix3d
  ) {
    for (const geomId of geomIds) {
      const geom = geomId.openObject();
      geom.setModelingMatrix(matrix);
      geom.delete();
    }
  }

  static setTextModelingMatrix(
    textIds: VisualizeJS.OdTvGeometryDataId[],
    matrix: VisualizeJS.Matrix3d
  ) {
    for (const textId of textIds) {
      // first get the translation which depends on the text position
      const text = textId.openAsText();
      const position = text.getAlignmentPoint();
      const position3d = OdaGeometryUtils.createPoint3dFromArray(position);
      const newPosition3d = OdaGeometryUtils.createPoint3dFromArray(position);
      newPosition3d.transformBy(matrix);

      const translation = newPosition3d.sub(position3d);
      const translationMatrix = OdaGeometryUtils.getTranslationMatrix(
        translation.toArray()
      );

      newPosition3d.delete();
      position3d.delete();
      translation.delete();
      text.delete();

      // finally, apply matrix to textId
      const geom = textId.openObject();
      geom.setModelingMatrix(translationMatrix);
      translationMatrix.delete();
      geom.delete();
    }
  }

  exportGeometryDataTyped(
    geomId: VisualizeJS.OdTvGeometryDataId
  ): GeometryDataTyped | undefined {
    switch (geomId.getType()) {
      case this.visLib.OdTvGeometryDataType.kText.value: {
        const text = geomId.openAsText();
        const data: GeometryDataTyped = {
          type: "text",
          refpoint: text.getAlignmentPoint(),
          alignmentmode: text.getAlignmentMode(),
          message: text.getString(),
        };
        text.delete();
        return data;
      }
      default:
        return undefined;
    }
  }
}
