import BaseEntityDragger from "./BaseEntityDragger";
import { HistoryEvents } from "@/open-cloud/commands/History";
import { Logger } from "@/logger";
import type { DRViewer } from "@/open-cloud/DRViewer";
import { EntityBuilder } from "@/open-cloud/builders/EntityBuilder";

export default class SequentialEntityDragger extends BaseEntityDragger {
  entity: VisualizeJS.OdTvEntityId | null = null; // in OdBaseDragger
  constructor(viewer: DRViewer) {
    super(viewer);
    this.viewer.addEventListener(HistoryEvents.UndoEnded, () => this.clear());
    this.viewer.addEventListener(HistoryEvents.RedoEnded, () => this.clear());
  }

  end(x: number, y: number, setNote = true) {
    if (this.endCornerWCS.length === 3) {
      const bufferEntId = this.createNew();
      if (bufferEntId && !bufferEntId.isNull()) {
        if (setNote) this._setNoteConfigProperties(bufferEntId);
        if (this.entity && !this.entity.isNull()) {
          const model = this.viewer.modelBuilder.findModel("ACTIVE");
          this.viewer.commandFactory.replaceEntities.execute([
            {
              old: {
                handle: EntityBuilder.getHandle(this.entity),
                layername: EntityBuilder.getLayerName(this.entity),
              },
              replacer: {
                handle: EntityBuilder.getHandle(bufferEntId),
                layername: EntityBuilder.getLayerName(bufferEntId),
              },
            },
          ]);
          model.delete();
        } else {
          this.viewer.commandFactory.addEntities.execute([
            {
              handle: EntityBuilder.getHandle(bufferEntId),
              layername: EntityBuilder.getLayerName(bufferEntId),
            },
          ]);
        }
        this.entity = bufferEntId;
      }
    }
    Logger.info(
      `SequentialEntityDragger.end : ${
        this.viewer.activeDragger()?.name
      } added an entity`
    );
    this.clearShadow();
    this.clearCoord();
  }

  _updateFrame(): void {
    if (this.endCornerWCS.length === 3) {
      this.refreshShadowEntity();
      this.drawShadow();
    }
  }

  createNew(): VisualizeJS.OdTvEntityId | null {
    return null;
  }

  drawShadow() {}

  clear() {
    super.clear();
    this.entity = null;
  }
}
